import React from 'react'
import { PROJECTS } from '../containts'
import { motion } from 'framer-motion'


const topic = (duration) => ({
    whileInView: {y:0, opacity:1},
    initial: {y:100, opacity:0},
    visible:{
        transition:{duration:duration},
    }
})

const Project = () => {
    return (
    <div id='project' className='border-b border-neutral-900 pb-4'>
    <motion.h1
         variants={topic(0.5)}
         whileInView="whileInView"
         initial="initial"
         transition="visible"     
        className='mt-20 mb-9 text-center text-4xl bg-gradient-to-r from-pink-400  to-purple-500 bg-clip-text tracking-tight text-transparent'>Project 
    </motion.h1>
    <div> 
        {PROJECTS.map((project, index) => (
            <div key={index} className='mb-8 flex flex-wrap lg:justify-center'>
                <motion.div
                  className='w-ful lg:w-1/4'
                  whileInView={{opacity:1, x:0}} 
                  initial = {{x:-100, opacity:0}}
                  transition={{duration:0.5}}
                >
                    <img 
                      src={project.image} 
                      width={300}
                      height={300}
                      alt={project.title}
                      className='mb-6 rounded '
                />
                </motion.div>
                <motion.div 
                  className='w-full max-w-xl lg:3/4'
                  whileInView={{opacity:1, x:0}} 
                  initial = {{x:100, opacity:0}}
                  transition={{duration:0.5}}  
                >
                   <h6 className='mb-2 font-semibold'>{project.title}</h6>
                   <p className='mb-4 text-neutral-400'>{project.description}</p>
                   {project.association.map((as, index) => (
                    <span key={index} className='mr-2 rounded bg-neutral-900 px-2 py-1 text-sm font-medium text-purple-500'>
                        {as}
                    </span>
                   ))}
                </motion.div>
            </div>
        ))}
    </div>
  </div>
    )
}

export default Project