import React from 'react'
import aboutImg from '../assets/about.png'
import { ABOUT_TEXT } from '../containts'
import { motion } from 'framer-motion'

const topic = (duration) => ({
    whileInView: {y:0, opacity:1},
    initial: {y:100, opacity:0},
    visible:{
        transition:{duration:duration},
    }
})

const About = () => {
  return (
    <div id='about' className='border-b border-neutral-900 pb-4'>
        <motion.h1
         variants={topic(0.5)}
         whileInView="whileInView"
         initial="initial"
         transition="visible" 
         className='my-20 text-center text-4xl bg-gradient-to-r from-pink-400  to-purple-500 bg-clip-text tracking-tight text-transparent'>About Me
        </motion.h1>
        <div className='flex flex-wrap'>
          <motion.div
           whileInView={{opacity:1, x:0}} 
           initial = {{x:-100, opacity:0}}
           transition={{duration:0.5}}
           className='w-full lg:w-1/2 lg:p-8'>
            <img className='rounded-2xl lg:max-w-xl' width={500} height={500} src={aboutImg} alt="aboutImg" />
          </motion.div>
          <motion.div 
           whileInView={{opacity:1, x:0}} 
           initial={{x:100, opacity:0}}
           transition={{duration:0.5}}
           className='w-full lg:w-1/2'>
            <div className='flex justify-center lg:justify-start'>
                <p className='my-2 max-w-xl py-6'>{ABOUT_TEXT}</p>
            </div>
          </motion.div>
        </div>
    </div>
  )
}

export default About