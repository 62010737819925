import React from 'react'
import stuydImg from '../assets/study.png'
import { STUDY_TEXT } from '../containts'
import { motion } from 'framer-motion'


const topic = (duration) => ({
    whileInView: {y:0, opacity:1},
    initial: {y:100, opacity:0},
    visible:{
        transition:{duration:duration},
    }
})


const Study = () => {
  return (
    <div id='study' className='border-b border-neutral-900 pb-4'>
        <motion.h1
         variants={topic(0.5)}
         whileInView="whileInView"
         initial="initial"
         transition="visible" 
         className='mt-20 mb-9 text-center text-4xl bg-gradient-to-r from-pink-400  to-purple-500 bg-clip-text tracking-tight text-transparent'>Study
        </motion.h1>
        <div>
           <h3 className="text-3xl py-1 dark:text-white text-center">Quantum Key Distribution</h3>
            <p className="text-md py-2 leading-8 text-gray-800 dark:text-gray-200">
              {STUDY_TEXT}
            </p>
        </div>
        <div className='flex flex-wrap'>
          <motion.div
           whileInView={{opacity:1, x:0}} 
           initial = {{x:-100, opacity:0}}
           transition={{duration:0.5}}
           className='w-full lg:w-1/2 lg:p-8'>
            <img className='lg:max-w-xl' width={600} height={600} src={stuydImg} alt="aboutImg" />
          </motion.div>
          <motion.div 
           whileInView={{opacity:1, x:0}} 
           initial={{x:100, opacity:0}}
           transition={{duration:0.5}}
           className='w-full lg:w-1/2'>
            <div className='flex justify-center lg:justify-start'>
                <p className='my-2 max-w-xl py-6'>{STUDY_TEXT}</p>
            </div>
          </motion.div>
        </div>
    </div>
  )
}

export default Study