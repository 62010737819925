import React, { useState } from 'react';
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa"
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { Link, animateScroll as scroll } from 'react-scroll';
import '../App.css';


// mb-20 flex item-center justify-between py-6

const Bar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return <nav className='mb-20 flex item-center justify-between py-6'>
  <div className='flex flex-shrink-0 item-center'>
      <h2 className='mt-6'>?????????????</h2>
  </div>
  <div className="m-4">
    <button 
          className="menu-toggle-button text-2xl hover:opacity-60 hover:cursor-pointer md:hidden" 
          onClick={toggleMenu}
    >
          ☰
    </button>

  {/* <FaLinkedin />
  <FaGithub />
  <FaSquareXTwitter />
  <FaInstagram /> */}
  <ul className={`flex item-center justify-center gap-10 text-2xl ${isMenuOpen ? 'block' : 'hidden'} md:flex`}>
    <li>
      <Link className='dvs-header__menuItems hover:opacity-60 hover:cursor-pointer' activeClass="active" to="about" spy={true} smooth={true} offset={-50} duration={600}>
        About
      </Link>
    </li>
    <li>
      <Link className='hover:opacity-60 hover:cursor-pointer' activeClass="active" to="hobby" spy={true} smooth={true} offset={-50} duration={600}>
        Hobby
      </Link>
    </li>
    <li>
      <Link className='hover:opacity-60 hover:cursor-pointer' activeClass="active" to="treasure" spy={true} smooth={true} offset={-50} duration={600}>
        Treasure
      </Link>
    </li>
    <Link className='hover:opacity-60 hover:cursor-pointer' activeClass="active" to="project" spy={true} smooth={true} offset={-50} duration={600}>
        Project
      </Link>
    <li>
      <Link className='hover:opacity-60 hover:cursor-pointer' activeClass="active" to="study" spy={true} smooth={true} offset={-50} duration={600}>
        Study
      </Link>
    </li>
  </ul>
  </div>
</nav>
}


export default Bar