import React from 'react'
import { motion } from 'framer-motion'
import { TbBook } from "react-icons/tb";
import { SiNetflix } from "react-icons/si";
import { SiAmazonprime } from "react-icons/si";
import { MdOutlineAddAPhoto } from "react-icons/md";
import { GiSoccerBall } from "react-icons/gi";
import { ImTwitch } from "react-icons/im";






const topic = (duration) => ({
    whileInView: {y:0, opacity:1},
    initial: {y:100, opacity:0},
    visible:{
        transition:{duration:duration},
    }
})


const iconVariant = (duration) => ({
  initial: {y: -10},
  animate: {
      y: [10, -10],
      transition: {
          duration:duration,
          ease: 'linear',
          repeat: Infinity,
          repeatType: "reverse"
      }
  },
})

const Hobby = () => {
    return (
      <div id='hobby' className='border-b border-neutral-800 pb-24'>
          <motion.h1 
           variants={topic(0.5)}
           whileInView="whileInView"
           initial="initial"
           transition="visible" 
           className='my-20 text-center text-4xl bg-gradient-to-r from-pink-400  to-purple-500 bg-clip-text tracking-tight text-transparent'>Hobby</motion.h1>
      



          <motion.div 
           whileInView={{opacity:1, x:0}} 
           initial = {{x:-100, opacity:0}}
           transition={{duration:1.5}}
           className='flex flex-wrap items-center justify-center gap-4'>
              <motion.div
               variants={iconVariant(2.5)}
               initial="initial"
               animate='animate'
               className='rounded-2xl border-4 border-neutral-800 p-4'>
                  <TbBook className='text-7xl text-cyan-400' />
              </motion.div>
              <motion.div 
               variants={iconVariant(3)}
               initial="initial"
               animate='animate'
               className='rounded-2xl border-4 border-neutral-800 p-4'>
                  <SiNetflix className='text-7xl text-red-700' />
              </motion.div>
              <motion.div 
               variants={iconVariant(3.5)}
               initial="initial"
               animate='animate'
               className='rounded-2xl border-4 border-neutral-800 p-4'>
                  <SiAmazonprime className='text-7xl text-cyan-600' />
              </motion.div>
              <motion.div
               variants={iconVariant(4)}
               initial="initial"
               animate='animate' 
               className='rounded-2xl border-4 border-neutral-800 p-4'>
                  <MdOutlineAddAPhoto className='text-7xl text-zinc-50' />
              </motion.div>
              <motion.div 
               variants={iconVariant(4.5)}
               initial="initial"
               animate='animate'
               className='rounded-2xl border-4 border-neutral-800 p-4'>
                  <GiSoccerBall className='text-7xl text-zinc-100' />
              </motion.div>
              <motion.div 
               variants={iconVariant(5)}
               initial="initial"
               animate='animate'
               className='rounded-2xl border-4 border-neutral-800 p-4'>
                  <ImTwitch className='text-7xl text-purple-500' />
              </motion.div>
          </motion.div>
      </div>
    )
  }
  
  export default Hobby