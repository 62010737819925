// import Hero from './components/Hero';
import About from './components/About';
import Bar from './components/Bar';
import Hobby from './components/Hobby';
import Intro from './components/Intro';
import Treasure from './components/Treasure';
import Study from './components/Study';
import Project from './components/Project';
// import Sidebar from './components/Sidebar';
import { useRef } from 'react';


const App = () => {
  const aboutRef = useRef();
  return (
    <div className="overflow-x-hidden text-neutral-300 antialiased selection:bg-cyan-300 selection:text-cyan-900">
      <div className='fixed top-0 -z-10 h-full w-full'>
        <div className="absolute inset-0 -z-10 h-full w-full items-center px-5 py-24 [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)]"></div>
      </div>      
      <div className='container mx-auto px-8'>
        
        <div> {/* This padding is to make space for the fixed Bar */}
          {/* <Sidebar /> */}
          <Bar />
          <Intro />
          <About />
          <Hobby />
          <Treasure />
          <Project/>
          <Study />
        </div>
      </div>
    </div>
  );
}

export default App;
