// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 760px) {
  .menu-toggle-button {
    display: block; 
  }

  ul {
    display: none; 
  }

  ul.block {
    display: flex; 
    flex-direction: column;
    gap: 10px; 
  }
}

@media (min-width: 770px) {
  .menu-toggle-button {
    display: none; 
  }

  ul {
    display: flex; 
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":["@media (max-width: 760px) {\n  .menu-toggle-button {\n    display: block; \n  }\n\n  ul {\n    display: none; \n  }\n\n  ul.block {\n    display: flex; \n    flex-direction: column;\n    gap: 10px; \n  }\n}\n\n@media (min-width: 770px) {\n  .menu-toggle-button {\n    display: none; \n  }\n\n  ul {\n    display: flex; \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
