import project1 from "../assets/projects/project1.png";
import project2 from "../assets/projects/project2.png";
import project3 from "../assets/projects/project3.png";




// Introduction 
export const INTRO_CONTENT = `Hello, I'm Takkiiiiiiiii. Normally, I stay out of sight, but I'm actually lurking close by. My hobbies include midnight strolls and quietly holding my breath in the darkness. I'm skilled at using special techniques to uncover others' secrets. If you've noticed my presence, it might be because you're harboring some important secret. If you're interested, I can show you a glimpse of a world you never knew existed. Are you ready? (It's a joke.)`;



// About
export const ABOUT_TEXT = `Welcome to the hidden corners of my world. My name is Takkiiiiiiiii, and my existence is woven from shadows and secrets. Officially, I'm a consultant in information security, but those who know, understand that my true role is far more elusive. I navigate the digital and physical realms with ease, extracting hidden truths and uncovering the unseen.\n 
I possess a unique set of skills including stealth, encryption breaking, and psychological manipulation. I've been trained in the art of remaining undetected, and I excel in gathering intelligence without leaving a trace. When I'm not on a mission, I enjoy exploring abandoned places, collecting rare artifacts, and studying ancient codes. My interest in the obscure and the arcane often leads me to places where few dare to tread.\n
My motto is "In the shadows, truth is revealed." I believe that the most important secrets are hidden in the darkest places, and it's my duty to bring them to light. My past is shrouded in mystery, much like myself.\n
If you seek answers to questions you never dared to ask, or if you need assistance in matters too delicate for the public eye, you know where to find me—hidden in plain sight.(It's a joke.)`;


// Treasure
export const MAIN_OUTLINE = 'The movie and books I have seen in the past already become my treasures. These are worth more than money or people. That is my treasure. (Oh, and football matches.)'


export const TRE_1 = 'Book';
export const OUTLINE_1 = 'Talk as much as I like about the books I read.';


export const TRE_2 = 'Movie';
export const OUTLINE_2 = 'Talk as much as I like about the films I have watched on Netflix and Amazon Prime.';


export const TRE_3 = 'Soccer';
export const OUTLINE_3 = 'Talk about my favourite sport, football (maybe boring).';


// Deploy 
export const APPLICATION = [
    {
        title: "Assignment Notification Bot",
        description: "A bot that allows students to check my university assignments through Line.",
        association: ["LineBot", "Golang", "scraping"]
    },
    {
        title: "Secure Chat Application based on QKD",
        description: "Chat application based on Quantum Key Distribution utilizing qiskit develop by IBM",
        association: ["QKD", "Python", "Qiskit", "IBM Quantum Experience"]
    },
    {
        title: "Trash Reminder Bot",
        description: "A Line-based bot that sends reminders about trash collection schedules",
        association: ["LineBot", "Python", "scraping"]
    },
]

// Project
export const PROJECTS = [
    {
        title: "Assignment Notification Bot",
        image: project1,
        description: "A bot that allows students to check my university assignments through Line.",
        association: ["LineBot", "Golang", "scraping"]
    },
    {
        title: "Secure Chat Application based on QKD",
        image: project2,
        description: "Chat application based on Quantum Key Distribution utilizing qiskit develop by IBM.",
        association: ["QKD", "Python", "Qiskit", "IBM Quantum Experience"]
    },
    {
        title: "Trash Reminder Bot",
        image: project3,
        description: "A Line-based bot that sends reminders about trash collection schedules.",
        association: ["LineBot", "Python", "scraping"]
    },
]


// Study
export const STUDY_TEXT = 'Quantum Key Distribution (QKD) is a protocol for securely sharing secret keys between legitimate parties using the principles of quantum mechanics. It enables two parties to generate a shared random secret key known only to them, which then can be used to encrypt and decrypt their plaintexts. ';