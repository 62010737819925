import React from 'react'
import {motion} from 'framer-motion';
import { TRE_1, TRE_2, TRE_3, MAIN_OUTLINE, OUTLINE_1, OUTLINE_2, OUTLINE_3 } from '../containts';
// import {Image} from 'react-native';
import { TbBook } from "react-icons/tb";
import { GiSoccerBall } from "react-icons/gi";
import { IoMdFilm } from "react-icons/io";



const topic = (duration) => ({
    whileInView: {y:0, opacity:1},
    initial: {y:100, opacity:0},
    visible:{
        transition:{duration:duration},
    }
})

const card = (duration) => ({
  whileInView: {y:0, opacity:1},
  initial: {y:100, opacity:0},
  visible:{
      transition:{duration:duration},
  }
})


const Treasure = () => {
  return (
    <div id='treasure' className='border-b border-neutral-900 pb-4'>
        <motion.h1
         variants={topic(0.5)}
         whileInView="whileInView"
         initial="initial"
         transition="visible" 
         className='mt-20 mb-9 text-center text-4xl bg-gradient-to-r from-pink-400  to-purple-500 bg-clip-text tracking-tight text-transparent'>Treasure
        </motion.h1>
        <motion.div
        variants={topic(0.5)}
        whileInView="whileInView"
        initial="initial"
        transition="visible">
            <h3 className="text-3xl py-1 dark:text-white text-center">Outline</h3>
            <p className="text-center text-md py-2 leading-8 text-gray-800 dark:text-gray-200">
              {MAIN_OUTLINE}
            </p>
          </motion.div>
          <div className="lg:flex gap-10">
            <motion.div 
            variants={card(1.5)}
            whileInView="whileInView"
            initial="initial"
            transition="visible" 
            className="text-center shadow-lg p-10 rounded-xl my-10  dark:bg-white flex-1">
              <div className='flex justify-center'>
               <div>
               <TbBook className='text-8xl text-slate-900 justify-center' />
               </div>
              </div>
              <h3 className="text-lg font-medium pt-8 pb-2  text-slate-900">
                {TRE_1}
              </h3>
              <p className="py-2 text-slate-900">
                {OUTLINE_1}
              </p>
              <h4 className="py-4 text-teal-600">Design Tools I Use</h4>
              <p className="text-gray-800 py-1">Photoshop</p>
              <p className="text-gray-800 py-1">Illustrator</p>
              <p className="text-gray-800 py-1">Figma</p>
              <p className="text-gray-800 py-1">Indesign</p>
            </motion.div>
            <motion.div 
            variants={card(1.5)}
            whileInView="whileInView"
            initial="initial"
            transition="visible" 
            className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white flex-1">
              <div className='flex justify-center'>
                <div>
                 <IoMdFilm className='text-8xl text-slate-900 justify-center'/>
                </div>
              </div>
              <h3 className="text-lg text-slate-900 font-medium pt-8 pb-2 ">
                {TRE_2}
              </h3>
              <p className="py-2 text-slate-900">
                {OUTLINE_2}
              </p>
              <h4 className="py-4 text-teal-600">Design Tools I Use</h4>
              <p className="text-gray-800 py-1">Photoshop</p>
              <p className="text-gray-800 py-1">Illustrator</p>
              <p className="text-gray-800 py-1">Figma</p>
              <p className="text-gray-800 py-1">Indesign</p>
            </motion.div>
            <motion.div 
            variants={card(1.5)}
            whileInView="whileInView"
            initial="initial"
            transition="visible" 
            className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white flex-1">
              <div className='flex justify-center'>
                {/* <img src={Design} width={100} height={100} className='justify-center'/> */}
                <div className=''>
                 <GiSoccerBall className='text-8xl text-slate-900 justify-center' />
                </div>
              </div>
              <h3 className="text-lg font-medium pt-8 pb-2 text-slate-900">
                {TRE_3}
              </h3>
              <p className="py-2 text-slate-900">
                {OUTLINE_3}
              </p>
              <h4 className="py-4 text-teal-600">Design Tools I Use</h4>
              <p className="text-gray-800 py-1">Photoshop</p>
              <p className="text-gray-800 py-1">Illustrator</p>
              <p className="text-gray-800 py-1">Figma</p>
              <p className="text-gray-800 py-1">Indesign</p>
            </motion.div>
            
          </div>
    </div>
  )
}

export default Treasure